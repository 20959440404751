import * as React from 'react'
import styled from 'styled-components'

import { Headings } from '../../components/TComponents/index'

import Image from '../../components/Image'
import mediaqueries from '../../styles/media'



interface PageHeroProps {
  page: any
  showHeader?: boolean
}

const PageHero: React.FC<PageHeroProps> = ({ page, showHeader }) => {
  const hasHeroImage =
    page.hero &&
    Object.keys(page.hero.full).length !== 0 &&
    page.hero.full.constructor === Object

  return (
    <Hero>
      {showHeader && (
        <Header>
          <HeroHeading>{page.title}</HeroHeading>
          <HeroSubtitle />
        </Header>
      )}
      {hasHeroImage && (
        <HeroImage id="ArticleImage__Hero">
          <Image src={page.hero.full} />
        </HeroImage>
      )}
    </Hero>
  )
}

export default PageHero

const Hero = styled.div`
  ${(p) => mediaqueries.phablet`
    &::before {
      content: "";
      width: 100%;
      height: 20px;
      background: ${p.theme.colors.background};
      position: absolute;
      left: 0;
      top: 0;
      transition: ${p.theme.colorModeTransition};
    }

    &::after {
      content: "";
      width: 100%;
      height: 10px;
      background: ${p.theme.colors.background};
      position: absolute;
      left: 0;
      top: 10px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      transition: ${p.theme.colorModeTransition};
    }
  `}
`

const Header = styled.header`
  position: relative;
  z-index: 10;
  margin: 100px auto 120px;
  padding-left: 68px;
  max-width: 749px;

  ${mediaqueries.desktop`
    padding-left: 53px;
    max-width: calc(507px + 53px);
    margin: 100px auto 70px;
  `}

  ${mediaqueries.tablet`
    padding-left: 0;
    margin: 100px auto 70px;
    max-width: 480px;
  `}

  ${mediaqueries.phablet`
    margin: 170px auto 180px;
    padding: 0 40px;
  `}

  @media screen and (max-height: 700px) {
    margin: 100px auto;
  }
`

const HeroHeading = styled(Headings.h1)`
  font-size: 48px;
  font-family: ${(p) => p.theme.fonts.serif};
  margin-bottom: 25px;
  font-weight: bold;
  line-height: 1.32;

  ${mediaqueries.tablet`
    margin-bottom: 20px;
    font-size: 36px;
  `}

  ${mediaqueries.phablet`
    font-size: 32px;
  `}
`

const HeroSubtitle = styled.div`
  position: relative;
  display: flex;
  font-size: 18px;
  color: ${(p) => p.theme.colors.grey};

  ${(p) => mediaqueries.phablet`
    font-size: 14px;
    flex-direction: column;

    strong {
      display: block;
      font-weight: 500;
      margin-bottom: 5px;
    }
  `}
`

const HeroImage = styled.div`
  position: relative;
  z-index: 1;
  width: 80%;
  max-width: 944px;
  overflow: hidden;
  margin: 15vh auto 0;
  box-shadow: 0 30px 60px -10px rgba(0, 0, 0, 0.2),
    0 18px 36px -18px rgba(0, 0, 0, 0.22);

  ${mediaqueries.tablet`
    max-width: 100%;
  `}

  ${mediaqueries.phablet`
    margin: 0 auto;
    width: calc(100vw - 40px);

    & > div {
      height: 220px;
    }
`}
`
