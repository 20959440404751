import * as React from 'react'
import { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import throttle from 'lodash/throttle'
import { graphql, useStaticQuery } from 'gatsby'

import { debounce  } from '@slipway/theme-components'
import mediaqueries from '../styles/media'
import MDXRenderer from '../components/MDX'
import SEO from '../components/SEO'
import Layout from '../components/Layout'

import PageShare from '../sections/Page/Pages.Share'
import PageHero from '../sections/Page/Page.Hero'


const siteQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            name
          }
        }
      }
    }
  }
`

const Page = ({ pageContext, location }) => {
  const contentSectionRef = useRef<HTMLElement>(null)

  const [hasCalculated, setHasCalculated] = useState<boolean>(false)
  const [contentHeight, setContentHeight] = useState<number>(0)

  const results = useStaticQuery(siteQuery)
  const { name } = results.allSite.edges[0].node.siteMetadata

  const { page } = pageContext
  console.debug('PAGE', page)
  console.debug('CONTENTSECTIONREF', contentSectionRef)

  useEffect(() => {
    const calculateBodySize = throttle(() => {
      const contentSection = contentSectionRef.current

      if (!contentSection) {
        return
      }

      /**
       * If we haven't checked the content's height before,
       * we want to add listeners to the content area's
       * imagery to recheck when it's loaded
       */
      if (!hasCalculated) {
        const debouncedCalculation = debounce(calculateBodySize)
        const $imgs = Array.from(contentSection.querySelectorAll('img'))

        $imgs.forEach(($img) => {
          // If the image hasn't finished loading then add a listener
          if (!$img.complete) {
            $img.onload = debouncedCalculation
          }
        })

        // Prevent rerun of the listener attachment
        setHasCalculated(true)
      }

      // Set the height and offset of the content area
      setContentHeight(contentSection.getBoundingClientRect().height)
    }, 20)

    calculateBodySize()
    window.addEventListener('resize', calculateBodySize)

    return () => window.removeEventListener('resize', calculateBodySize)
  }, [])

  return (
    <Layout
      location={location}
      basePath={pageContext.basePath}
      locale={pageContext.locale}
      languages={pageContext.languages}
      translations={pageContext.translations}
    >
      <SEO
        title={`${name} - ${page.title}`}
        canonicalUrl={page.canonical_url}
        pathname={location.href}
        description={page.excerpt || undefined}
        image={page.hero.seo.src || undefined}
      />
      <PageHero page={page} />
        <PageBody ref={contentSectionRef}>
        <MDXRenderer content={page.body}>
          <PageShare />
        </MDXRenderer>
      </PageBody> 
    </Layout>
  )
}

export default Page

const PageBody = styled.article`
  position: relative;
  transition: background 0.2s linear;

  ${mediaqueries.desktop`
    padding: 20px 0 20px 0;
  `}

  ${mediaqueries.tablet`
    padding: 0px 0 20px;
  `}

  ${mediaqueries.tablet`
    padding: 0px 0 20px;
  `}

  ${mediaqueries.phablet`
    padding: 0px 0 20px 0;
  `}
`
